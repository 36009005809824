import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'

import Header from '../components/Header/Header'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import MPUBanner from '../components/Banners/MPUBanner/MPUBanner'
import BlocksWrappers from '../components/Blocks/BlocksWrappers'
import Highlights from '../components/Highlights/Highlights'
import CTARow from '../components/CTA/CTARow'
// import Instagram from '../components/Instagram/Instagram'
import Footer from '../components/Footer/Footer'
import Social from '../components/Social/Social'
import PageMeta from '../components/PageMeta/PageMeta'
import { type IFrontPageQueryData } from '../graphql/queries/FrontPageQuery'

import * as BlockStyles from '../components/Blocks/Blocks.module.scss'
import './index.scss'
import * as styles from '../components/layoutComponents/littleBlackBook/LittleBlackBook.module.scss'
import Blocks from '../components/Blocks/Blocks'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        description
        title
        siteId
        egSettingTwitter
        url
      }
      nationalReaderTreats {
        id
        title
        uri
        homepagePreviewImage
      }
      whatsOnPosts: posts(
        where: {
          categoryName: "this-month"
          orderby: { field: DATE, order: DESC }
        }
        first: 1
      ) {
        nodes {
          id
          title
          excerpt
          uri
          featuredImage {
            node {
              mediaItemUrl
              altText
              homepagePreviewImage: sourceUrl(size: HOMEPAGE_PREVIEW)
            }
          }
        }
      }
      gottaHaveItPosts: nationalPosts(category: "GOTTA HAVE IT", first: 1) {
        id
        title
        uri
        linkType
        excerpt
        homepagePreviewImage
      }
      littleBlackBooks {
        nodes {
          id
          title
          uri
          featuredImage {
            node {
              mediaItemUrl
              altText
              spotlightDesktop: sourceUrl(size: HOMEPAGE_SPOTLIGHT_DESKTOP)
              spotlightMobile: sourceUrl(size: HOMEPAGE_SPOTLIGHT_MOBILE)
              homepagePreviewImage: sourceUrl(size: HOMEPAGE_PREVIEW)
            }
          }
          primaryCategory
          primaryCategoryURI
        }
      }
      sponsoredCategories {
        slug
        title
      }
      adStarCodes {
        id
        adslot
      }
      adConfiguration
    }
  }
`

interface FrontPageProps {
  data: IFrontPageQueryData
}

const FrontPage = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      sponsoredCategories,
      whatsOnPosts,
      adConfiguration,
      gottaHaveItPosts,
      littleBlackBooks,
      nationalReaderTreats
    }
  }
}: FrontPageProps) => {
  const { siteId, title } = generalSettings
  const [isMobile, setIsMobile] = useState(false)
  /* If ad code has been added, display DMPU */
  const DMPUAdSlot = adStarCodes.find(ad => ad.adslot === 'dmpu')

  const adsToShow = adStarCodes.filter(
    ad =>
      ad?.adslot?.toLowerCase().includes('mpu') ||
      ad?.adslot?.toLowerCase().includes('lb')
  )

  // console.log(littleBlackBooks)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches)
    }
  }, [])

  console.log('sponsored', sponsoredCategories)
  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          image:
            'https://bucksoxon.muddystilettos.co.uk/wp-content/uploads/sites/3/2021/02/Girl-Cow-original.jpg'
        }}
      />
      <Header siteId={siteId} siteName={title} />
      {adStarCodes && (
        <>
          <TopBanner
            ads={adsToShow}
            category={`homepage`}
            subCategory={`homepage`}
            pageType={`homepage`}
            showMPUs
            showAdditionalMPUs={adConfiguration}
          />
          <Leaderboard adSlot="TopLB" sticky={true} />
        </>
      )}
      <div className={BlockStyles.Outer}>
        <div style={{ order: 3 }}>
          <Highlights
            gottaHaveIt={gottaHaveItPosts}
            whatsOn={whatsOnPosts}
            siteId={siteId ?? null}
            siteName={generalSettings.title ?? undefined}
          />
        </div>
        <MPUBanner
          order={2}
          adSlots={['DMPU', 'Row1MPU1', 'Row1MPU2', 'Row1MPU3', 'Row1MPU4']}
          hasDMPU={Boolean(DMPUAdSlot?.id)}
        />
        <CTARow order={4}>
          <h3>Get Muddy</h3>
          <p>
            Sign up for our inside line on the most brilliant things happening
            near you
          </p>
          <p>
            <Link to="/sign-up">Sign up for our free newsletter</Link>
          </p>
        </CTARow>
        {/* <Instagram /> */}
        {!isMobile && <Leaderboard adSlot="MidLB1" sticky order={3} />}
        <MPUBanner
          order={4}
          adSlots={['Row2MPU1', 'Row2MPU2', 'Row2MPU3', 'Row2MPU4']}
        />
        {siteId && siteId > 1 && (
          <CTARow colour={`Black`} order={12}>
            <h3>
              Lit<span className={styles.kerning}>t</span>le Black Book
            </h3>
            <p>
              Our A-Z of the grooviest local businesses to help make your life
              easier
            </p>
            <p>
              <Link to="/little-black-book/">View the businesses</Link>
            </p>
          </CTARow>
        )}
        {sponsoredCategories.map((sponsoredCategory, index: number) => (
          <BlocksWrappers
            slug={sponsoredCategory.slug || ''}
            title={sponsoredCategory.title || ''}
            key={index}
            index={index}
            mobileAdBlock={
              sponsoredCategory.slug === 'SPOTLIGHT'
                ? 'MidLB1'
                : sponsoredCategory.slug === 'TRENDING'
                ? 'MidLB2'
                : undefined
            }
          />
        ))}
        {(adConfiguration === 1 || adConfiguration === 2) && (
          <MPUBanner
            order={5}
            adSlots={['Row3MPU1', 'Row3MPU2', 'Row3MPU3', 'Row3MPU4']}
          />
        )}
        {siteId &&
          siteId > 1 &&
          littleBlackBooks.nodes &&
          littleBlackBooks.nodes.length > 0 && (
            <Blocks
              darkMode={true}
              title={`New on the Little Black Book`}
              posts={littleBlackBooks.nodes}
              hasSlider
              maxResults={8}
              settings={{ position: 5 }}
            />
          )}

        {/* {nationalReaderTreats && nationalReaderTreats.length > 0 && (
          <Blocks
            // darkMode={true}
            title={`Summer Lovin'!`}
            posts={nationalReaderTreats.filter(
              treat => treat.title && treat.title.indexOf('Summer Lovin') > -1
            )}
            hasSlider
            maxResults={16}
            settings={{ position: 3 }}
          />
        )} */}
        <CTARow order={11}>
          <h3>Follow us!</h3>
          <p>See what we’re up to on your favourite social media networks</p>
          <Social />
        </CTARow>
        {adConfiguration === 2 && (
          <MPUBanner
            order={10}
            adSlots={['Row4MPU1', 'Row4MPU2', 'Row4MPU3', 'Row4MPU4']}
          />
        )}
        <Leaderboard
          order={12}
          adSlot={siteId && siteId > 1 ? 'BottomLB' : 'MidLB1'}
        />
      </div>
      <Footer />
    </>
  )
}

export default FrontPage
