import React, { ReactNode, useContext, useEffect, useState } from 'react'
import * as HomepageCTAStyles from './HomepageCTA.module.scss'
import { Link } from 'gatsby'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../../lib/utils'
// import CountdownClock from './CountdownClock'

const HomepageCTA = ({ siteId }: { siteId?: number }) => {
  const AwardsContext = useContext(MuddyAwardsContext)
  const [content, setContent] = useState<ReactNode>()

  useEffect(() => {
    switch (AwardsContext) {
      case MuddyAwardsStates.NOMINATIONS:
        setContent(
          <>
            <p>
              <Link to={`/awards/nominate`}>
                NOMINATE NOW IN THE MUDDY AWARDS 2024
              </Link>
            </p>
            <p>
              Support the local lifestyle businesses you love and be in with a
              chance to{' '}
              <a
                href="https://muddystilettos.co.uk/reader-treats/win-two-night-stay-the-pig-hotel-of-your-choice/"
                target="_blank"
                rel="noreferrer"
              >
                WIN a £1,250 two night stay at THE PIG hotel of your choice
              </a>
            </p>
            <p>
              <Link to={`/awards/`}>Find out more</Link>
            </p>
          </>
        )
        break

      case MuddyAwardsStates.NOMINATIONS_CLOSED:
        setContent(
          <>
            <p>GET READY FOR THE REGIONAL FINALS!</p>
            <p>
              Your nominations are being counted (and counted, and counted, and
              counted…) and verified. Finalists will be announced and voting
              will open on <u>3rd April 2024</u>. Watch this space!
            </p>
          </>
        )
        break

      case MuddyAwardsStates.VOTING:
        setContent(
          <>
            <p>MUDDY AWARDS 2024 REGIONAL FINALS ARE OPEN!</p>
            <p>
              VOTE for the local lifestyle businesses you love{' '}
              <Link to={`/awards/vote/`}>here</Link>. Plus,{' '}
              <a
                href="https://muddystilettos.co.uk/reader-treats/win-two-night-stay-the-pig-hotel-of-your-choice/"
                target="_blank"
                rel="noreferrer"
              >
                WIN a £1,250 two night stay at THE PIG hotel of your choice
              </a>
            </p>
            <p>
              <Link to={`/awards/`}>Find out more</Link>
            </p>
          </>
        )
        break

      case MuddyAwardsStates.VOTING_CLOSED:
        setContent(
          <>
            <p>
              MUDDY AWARDS 2024 REGIONAL FINALS ARE CLOSED. WINNERS ANNOUNCED
              2nd May.
            </p>
            <p>
              Still time to be in with a chance to{' '}
              <a
                href="https://muddystilettos.co.uk/reader-treats/win-two-night-stay-the-pig-hotel-of-your-choice/"
                target="_blank"
                rel="noreferrer"
              >
                WIN a £1,250 two night stay at THE PIG hotel of your choice
              </a>
            </p>
            <p>
              <Link to={`/awards/`}>Find out more</Link>
            </p>
          </>
        )
        break

      case MuddyAwardsStates.WINNERS:
        setContent(
          <>
            <p>THE RESULTS ARE IN!</p>

            <p>
              Find out who you crowned as Muddy Awards 2024 Regional Winners!
            </p>

            <p>DRUM ROLL, PLEASE...</p>
            <p>
              <Link
                to={
                  siteId && siteId === 1
                    ? `/awards/counties/`
                    : `/awards/winners/`
                }
              >
                {siteId && siteId === 1
                  ? `MEET THE REGIONAL WINNERS HERE!`
                  : `MEET YOUR WINNERS HERE!`}
              </Link>
            </p>
          </>
        )
        break

      case MuddyAwardsStates.NATIONAL_WINNERS:
        setContent(
          <>
            <p>THE RESULTS ARE IN!</p>

            <p>
              Find out who was crowned as this year’s National Muddy Awards 2024
              Winners!
            </p>

            <p>DRUM ROLL, PLEASE...</p>
            <p>
              <Link to={`/awards/national-results/`}>
                MEET THE NATIONAL WINNERS HERE!
              </Link>
            </p>
          </>
        )
        break

      case MuddyAwardsStates.NATIONAL_FINALISTS:
        setContent(
          <>
            <p>MEET YOUR MUDDY AWARDS NATIONAL FINALISTS</p>
            <p>
              Who made the cut in the year’s National Muddy Awards? Buckle up,
              as we reveal the 105 outstanding lifestyle businesses who made the
              cut.
            </p>
            <p>
              Plus check out all the Regional Winners{' '}
              <Link
                to={
                  siteId && siteId === 1
                    ? `/awards/counties`
                    : `/awards/winners/`
                }
              >
                Here
              </Link>
              .
            </p>
            <p>
              <a href="https://muddystilettos.co.uk/awards/national-results/">
                MEET THE NATIONAL FINALISTS HERE!
              </a>
            </p>
          </>
        )
        break
      default:
        setContent(
          <>
            <p>
              <Link to={`/awards/nominate`}>
                NOMINATE NOW IN THE MUDDY AWARDS 2024
              </Link>
            </p>
            <p>
              Support the local lifestyle businesses you love and be in with a
              chance to{' '}
              <a
                href="https://muddystilettos.co.uk/reader-treats/win-two-night-stay-the-pig-hotel-of-your-choice/"
                target="_blank"
                rel="noreferrer"
              >
                WIN a £1,250 two night stay at THE PIG hotel of your choice
              </a>
            </p>
            <p>
              <Link to={`/awards/`}>Find out more</Link>
            </p>
          </>
        )
        break
    }
  }, [AwardsContext])

  return (
    <>
      <div className={`${HomepageCTAStyles.Block_Img}`}>
        <img
          src="/images/awards/Awards_Badge_2024.gif"
          alt={'Muddy Awards 2024'}
        />
      </div>
      <div className={HomepageCTAStyles.Block_Text}>{content}</div>
    </>
  )
}

export default HomepageCTA
